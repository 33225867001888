import { Card, Text, useTheme, Modal, Button } from "@nextui-org/react";
import { useState } from "react";
import { FaRegCopy } from "react-icons/fa";

export const SlotCard = props => {
    const { theme } = useTheme();
    const [modal, setModal] = useState(false);

    const { start, end } = props.event
    const startD = new Date(start.dateTime);
    const endD = new Date(end.dateTime);

    const template = `Hi Olly,\n\n
I'm interested in booking a free instruction session with you.\n\n
The following slot works for me:\n\n
${dateFormat(start.dateTime)} for ${timeDiffToHours(endD - startD)} Hrs\n\n
Regards,\n
<YOUR NAME HERE>`;

  const emailAddr = "olivercchapman123@gmail.com";

    return (<>
        <div style={{padding:'10px'}}>
            <Card
              isPressable
              isHoverable
              variant="bordered"
              onPress={()=>{setModal(true)}}
              style={{color: theme.colors.primary.value, paddingLeft:'15px'}}
            >
              <Card.Body>
                <Text >{dateFormat(start.dateTime)}</Text>
              </Card.Body>
              <Card.Divider/>
              <Card.Footer>{timeDiffToHours(endD - startD)} Hrs</Card.Footer>
            </Card>
        </div>
        <Modal
        closeButton
        blur
        width="80%"
        open={modal}
        onClose={()=>{setModal(false)}}
        className="modal"
      >
        <Modal.Header style={{display:"flex", flexDirection:"column", paddingLeft:"10%", paddingright:"10%"}}>
        <div style={{display:"flex", flexDirection:"row"}}>
          <Text className="scale-text large" style={{paddingRight:"10%"}}>
          Send me an email at
          <a style={{textDecoration:"none", color:theme.colors.primary.value, }} href={`mailto:${emailAddr}?subject=Free Instruction&body=${template}`}> {emailAddr}</a>
          </Text>
        </div>
        
        <Text className="scale-text medium">
          <br/>Here's an email template with your slot. Feel free to make it your own - don't
          hesistate to ask any questions or let me know what you might be keen to learn.
        </Text>
        </Modal.Header>
        <Modal.Body>
        <Card
              variant="bordered"
              style={{color: theme.colors.primary.value, paddingLeft:'5%', paddingRight:'5%'}}
            >
              <Card.Body>
                {template.split('\n').map((line, idx) => <Text className="scale-text small" key={idx} style={{lineHeight:"2"}}>{line}</Text>)}
              </Card.Body>
            </Card>
        <Button style={{width:"auto"}} onPress={()=>{navigator.clipboard.writeText(template)}}>
        <FaRegCopy size={20} style={{paddingRight:"10px"}}/> <Text color="white" weight="bold">Copy</Text>
        </Button>
        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
        </>
    )
}

const dateFormat = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.toUTCString().slice(0,-7)}`
}

const timeDiffToHours = (timeDiff) => {
    return timeDiff / (1000 * 60 * 60)
}