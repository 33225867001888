import { useEffect, useState } from "react";
import { eventsFormatter } from "../functions/eventsFormatter.functions";
import { getCalEvents } from "../api/getCalEvents.api";
// import { items } from "./itemsDev";

const useCalEvents = () => {

    const [ events, setEvents ] = useState(null)

    async function getAndSetEvents( stateSetter ) {

        const items = await getCalEvents();
        // console.log(items)
    
        stateSetter(eventsFormatter(items))
    }

    useEffect(()=>{
        getAndSetEvents(setEvents)
    },[])

    return {
        events,
        setEvents
    }
}

export default useCalEvents