import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BookingScreen from './screens/booking.screen'
import HomeScreen from './screens/home.screen'
import { EventsContext } from './contexts/events.context';
import useCalEvents from './hooks/useCalEvents.hook';

function App() {

  const { events, setEvents } = useCalEvents()

  return (
    <Router>
      <EventsContext.Provider value={{ events, setEvents }}>
        <Routes>
          <Route element={<HomeScreen/>} path="/"/>
          <Route element={<BookingScreen/>} path="/Booking"/>
        </Routes>
      </EventsContext.Provider>
    </Router>
  );
}

export default App;
