import { responseHandler } from "./utils.api"

export async function getCalEvents() {

    //pass filtering params in query string
    let url = process.env.REACT_APP_BACKEND_URL + '/getSlots'

    return await fetch(url,{
        method: 'GET'
    }).then( (res)=>responseHandler(res) )
    .then((data) => data.json())

}