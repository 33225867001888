import { useContext } from "react";
import { CustomCard, SlotCard } from "../components/slotCard";
import { EventsContext } from "../contexts/events.context";
import CoverImage from "../components/coverImage.component";
import { useTheme, Text, Card } from "@nextui-org/react";
import "../styles/accentButton.style.css";
import { ImgCard } from "../components/imgCard";

const HomeScreen = () => {
  const { theme } = useTheme();

  const { events } = useContext(EventsContext);

  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
    >
      <div style={{ flex: 2, minWidth: "350px", padding: "10px" }}>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <Card css={{ padding: "15px" }}>
            <Card.Body>
              <Text
                color={theme.colors.primary.value}
                className="scale-text large"
                weight="bold"
                style={{ lineHeight: 1.5, margin: 0 }}
              >
                Free Instruction? <br />
                I'm Ollie, a trainee MCI working in North Wales
              </Text>
            </Card.Body>
          </Card>
        </div>
        <ImgCard
          imgUrl={
            "https://climbing-with-oliver-resources.s3.eu-west-1.amazonaws.com/headshot.jpg"
          }
        />
        <div style={{ marginTop: "10px" }}>
          <Card css={{ padding: "15px" }}>
            <Card.Body>
              <Text className="scale-text small">
                Are you interested in a free mountaineering instruction session?
                This is a chance for you to get out and learn new skills whilst
                helping me to progress towards my Mountaineering and Climbing
                Instructor Award.
              </Text>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div
        style={{
          padding: "15px",
          flex: 1,
          minWidth: "300px",
          backgroundColor: theme.colors.gray50.value,
          height: "100vh",
        }}
      >
        <div style={{ paddingLeft: "15px" }}>
          <Text size={20} style={{ color: theme.colors.primary.value }}>
            Available Slots
          </Text>
        </div>
        {events &&
          events.map((event) => <SlotCard key={event.id} event={event} />)}
      </div>
    </div>
  );
};

export default HomeScreen;
