import { Card, Text, useTheme, Row, Button, Col } from "@nextui-org/react";
import { IoLogoInstagram } from "react-icons/io";

export const ImgCard = props => {
    const { theme } = useTheme();

    return (
        <div >
            <Card css={{ }}>
            {/* <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
              <Col>
                <Text size={25} weight="bold" color="#000000" style={{fontFamily:'sono'}}>
                  Free Instruction?
                </Text>
                <Text size={25} weight="bold" color="#000000" style={{fontFamily:'sono', width:'30%'}}>
                  I'm Ollie, a trainee MCI working in North Wales.
                </Text>
              </Col>
            </Card.Header> */}
              <Card.Body css={{ p: 0 }}>
                <Card.Image
                  src={props.imgUrl}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  
                />
              </Card.Body>
              <Card.Divider/>
              <Card.Footer isBlurred css={{
                  position: "absolute",
                  bgBlur: "#00000090",
                  borderTop: "$borderWeights$light solid rgba(255, 255, 255, 0.2)",
                  bottom: 0,
                  zIndex: 1,
                }}>
                  <Row style={{alignItems:"center"}}>
                    <Col style={{flex:3, paddingLeft: "15px"}}>
                      <Text color="white" weight={"bold"} className="scale-text medium">
                        Select a time slot - or message me on Instagram
                      </Text>
                    </Col>
                    <Col style={{flex:1, paddingRight: "15px"}}>
                      <Button 
                      style={{backgroundColor:"transparent", borderColor:"white", }}
                      onPress={()=>{window.location.replace("https://www.instagram.com/olly_chapman47/")}}
                      className="hover-enlarge"
                      >
                      <IoLogoInstagram size={40} className="scale-text medium" color={theme.colors.primary.value} />
                      <Text className="scale-text small" color="white">olly_chapman47</Text>
                        </Button>
                    </Col>
                  </Row>
                </Card.Footer>
            </Card>
        </div>
    )
}