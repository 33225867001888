

const BookingScreen = () => {

    return (
        <div>
            <p>Do your booking here</p>
        </div>
    )
}

export default BookingScreen